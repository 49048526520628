import React from 'react';
import PropTypes from '../PropTypes';
import Footer from './Footer';

import '../styles/index.scss';

const propTypes = {
  children: PropTypes.node.isRequired
};

function SpecialLayout({ children }) {
  return (
    <div className="special-page">
      {children}
      <Footer name="twin-peaks" />
    </div>
  );
}

SpecialLayout.propTypes = propTypes;

export default SpecialLayout;
