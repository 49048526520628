import React from 'react';
import classNames from 'classnames';
import PropTypes from '../../../PropTypes';
import Split from './Split';
import Figure from './Figure';

const propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  image: PropTypes.any
};

const defaultProps = {
  inverted: false,
  image: null
};

function Section({ inverted, header, image, children }) {
  return (
    <section
      className={classNames('article_section-wrapper', {
        'article_section-wrapper__inverted': inverted
      })}
    >
      <div className="article_section">
        {image && <Figure {...image} />}
        <h1>{header}</h1>
        {children}
      </div>
      <div
        className={classNames('article_split', {
          article_split__inverted: inverted
        })}
      >
        <Split />
      </div>
    </section>
  );
}

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;
