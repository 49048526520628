import React from 'react';
import uniqueId from 'lodash/fp/uniqueId';

const Split = () => {
  const id = uniqueId('pattern_');

  return (
    <svg id={`svg_${id}`} style={{ width: '100%', height: '100%' }}>
      <defs>
        <pattern
          id={id}
          viewBox="0 0 125 80"
          x="0"
          y="0"
          width="20"
          height="50"
          patternUnits="userSpaceOnUse"
        >
          <polygon className="shape" points="0 80 0 54 63 0 126 54 126 80 63 27 0 80" />
        </pattern>
      </defs>
      <rect className="rect" x="0" y="0" width="100%" height="100%" fill={`url(#${id})`} />
    </svg>
  );
};

export default Split;
