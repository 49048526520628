import React from 'react';
import Img from 'gatsby-image';

function Figure(props) {
  return (
    <figure className="figure">
      <Img className="figure_image" {...props} />
    </figure>
  );
}

export default Figure;
