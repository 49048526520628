import map from 'lodash/fp/map';
import keyBy from 'lodash/fp/keyBy';
import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Section from './components/Section';
import SpecialLayout from '../../components/SpecialLayout';
import SocialLinks from '../../components/SocialLinks';
import SEO from '../../components/SEO';
import PropTypes from '../../PropTypes';

/* eslint max-len: ["error", { "code": 800 }] */

const propTypes = {
  data: PropTypes.shape({
    images: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            childImageSharp: PropTypes.image.isRequired,
            name: PropTypes.string.isRequired
          }).isRequired
        })
      ).isRequired
    }).isRequired,
    cover: PropTypes.shape({
      childImageSharp: PropTypes.image.isRequired
    }).isRequired,
    share: PropTypes.shape({
      childImageSharp: PropTypes.image.isRequired
    }).isRequired
  }).isRequired
};

function TwinPeaks({ data }) {
  const mapped = map('node')(data.images.edges);
  const images = keyBy('name')(mapped);
  const slug = '/twin-peaks';

  return (
    <SpecialLayout>
      <article className="article article__twin-peaks">
        <SEO
          post={{
            title: 'Twin Peaks — Незабываемый сон',
            description: 'Секреты этого вечного сериала',
            slug,
            date: '2018-08-14'
          }}
          image={data.share.childImageSharp.sizes.src}
          themeColor="#c1c5e6"
        />
        <div className="welcome">
          <header className="cover">
            <Img
              outerWrapperClassName="background-image"
              className="cover_image"
              {...data.cover.childImageSharp}
              style={{ position: 'absolute' }}
            />
            <div className="cover_foreground">
              <div className="cover_header">
                <Link to="/">Dark&nbsp;Fields</Link>
              </div>
              <div className="cover_main">
                <h1 className="cover_title">TWIN&nbsp;PEAKS</h1>
                <div className="cover_lead element">
                  Вспомним странный и прекрасный сон, придуманный Дэвидом Линчем. Хотя его и так
                  невозможно забыть
                </div>
              </div>
              <div className="cover_rune">ᛟ</div>
            </div>
          </header>
        </div>

        <Section header="Добро пожаловать в&nbsp;Твин&nbsp;Пикс">
          <p className="first">
            Даже если каким-то чудом вы избежали просмотра этого сериала, вы точно сталкивались с
            его кадрами. Вы могли не знать, что перед вами «Твин&nbsp;Пикс», но чувствовали, что это
            отрывок чего-то особенного. Древнее соотношение сторон, выверенная композиция,
            гипермягкие цвета. А ещё красивые девушки и, скорее всего, непонимающее лицо человека,
            который пьёт кофе.
          </p>

          <p>
            Что же такое «Твин&nbsp;Пикс»? Это маленький городок из 90-х, которому удалось за два
            коротких сезона разрастись до целого вымышленного мира. После конца эфира рост не
            остановился, вселенная продолжала расширяться дальше, за рамки телевизора в разумы
            фанатов, в реальность. Телевизионное шоу стало культом. Даже учитывая, что на это ушло
            20 лет, остаётся вопрос — как?
          </p>
        </Section>

        <Section
          header="Драма"
          image={{
            ...images.laura.childImageSharp,
            alt: "Laura's picture from the prom night"
          }}
        >
          <p>
            Как книга редко задевает с первой страницы, так и сериалу сложно достучаться первым
            эпизодом. Тем более, всё сильное долго набирает разбег. Но с «Твин Пиксом» иначе. С
            первой серии, почти с первых сцен, сериал уже способен пробить на слезы. Мы пока не
            знаем ни персонажей, ни правил мира, но уже чувствуем грядущую драму. Всё начинается с
            одного простого события. Я его кратко опишу, но не волнуйтесь, этот факт такой же не
            спойлер, как и смерть Иисуса в Библии.
          </p>

          <p>
            В маленьком городе на берегу реки находят тело прекрасной девушки — Лоры Палмер. Одно за
            другим нам показывают реакции жителей городка. Каким-то образом Дэвид Линч и Марк Фрост
            (второй создатель сериала) смогли достоверно передать за одну серию состояние всего
            сообщества, которое впало в шок от смерти девушки, — её любили все. Новость разлетается
            по городу, как чума, задевая каждого персонажа. Так и происходит знакомство. Это одна из
            причин успешности сериалов. Сильная, достоверная драма и история. Проработанные, живые
            персонажи, даже второстепенные.
          </p>
        </Section>

        <Section
          header="Линчевание"
          image={{
            ...images.cooper.childImageSharp,
            alt: "standing Cooper doesn't understand anything"
          }}
        >
          <p>
            Что значило участие Линча именно в этом проекте? Нет, не сюрреалистичная красивая дичь,
            которой он славится. Важно понимать, что у Линча были связаны руки продюсерами, и это
            пошло проекту на пользу — его гениальность и творческий беспредел нашли баланс.
          </p>

          <p>
            С одной стороны, мы видим мир, где мифологии перемешиваются друг с другом, символы
            наслаиваются, образы переходят из реальности в реальность, оставляя за собой след, и по
            нему пытаются двигаться персонажи. При всём этом герои ведут себя логично, как настоящие
            люди, как вели бы себя вы. Часто агент Купер, главный герой, буквально читает твои
            мысли, и ведет себя так, будто он персонаж компьютерной игры, над которым у тебя есть
            полный контроль. Однако, к сожалению, этот эффект сходить на нет после первого сезона,
            но по-прежнему что бы ни происходило на экране, всё подчиняется законам логики. Впрочем,
            у Линча всегда так, просто зачастую эти законы известны только ему.
          </p>
        </Section>

        <Section
          header="Хоррор"
          image={{
            ...images.mask.childImageSharp,
            alt: 'creepy mask on the bed under the blanket'
          }}
          inverted
        >
          <p>
            Кроме того, что «Твин Пикс» и так имеет тёмные мотивы (убийства, наркотики,
            проституция), он может вызывать и чистый ужас. На момент выхода сериала киноделы уже
            давно знали лёгкий способ напугать зрителя. Резко показать на экране около-человеческую
            рожу под такой же резкий громкий звук. Пугает? Да. Вызывает остановку сердца? Иногда. Но
            если пациент останется жив, то эффект после такого испуга пройдет почти мгновенно. Линч
            играет по другим правилам. Опишу один из его приёмов.
          </p>

          <p>
            Представьте следующую атмосферу. Вы заходите к себе домой, самое безопасное место на
            земле, и застаете там обычного (по меркам хорроров) человека, не зомби, не мутанта. Он
            сидит в центре вашей гостиной, на корточках, опираясь руками об пол, поворачивается в
            вашу сторону и замирает с глупой улыбкой. Не нападает, не кричит. Теперь страх меняет
            вектор. Потому что в отличие от мерзкой твари, невозможно объяснить себе поведение
            незнакомца. Тварь ведет себя странно по определению, но сейчас перед нами обычный на вид
            человек. Как он здесь оказался? Что ему нужно? Почему он молчит? Страх нарастает с
            пониманием — такое возможно и в реальной жизни. Проверьте прямо сейчас, замкнута ли ваша
            дверь.
          </p>
        </Section>

        <Section
          header="Атмосфера"
          image={{
            ...images.cafe.childImageSharp,
            alt: 'atmospheric night view of a cafe with a red neon sign'
          }}
        >
          <p>
            Название города не зря вынесено в название — это не просто фон, а полноценный персонаж.
            У него есть свой характер, своё настроение. Помните, я написал, что действие
            разворачивается в 90-х? Я соврал, потому что наверняка сказать нельзя — все состояния в
            этом городке пограничны. Граница десятилетий прошлого века, граница США и Канады,
            граница сознания и сна.
          </p>

          <p>
            Декорации быстро становятся любимыми местами. Придорожное кафе, полицейский участок,
            лес. У них есть свой визуальный код, тема, как и у других героев. Причем темы
            проявляются не только в визуальной части, но и в звуковой. Такой приём прививает зрителю
            условную реакцию на происходящее, и уже спустя несколько серий у вас появляется улыбка
            или слезы, или ненависть от одних первых нот музыкальной темы и начального, адресного,
            плана сцены. Музыку в сериале вообще можно считать отдельным мини-шедевром. Анджело
            Бадаламенти, композитор саундтрека, смог передать видение Линча так, что сложно сказать,
            могла ли музыка существовать без видеоряда и наоборот.
          </p>
        </Section>

        <Section
          header="Afterlife"
          image={{
            ...images.room.childImageSharp,
            alt: 'big weird room with smaller person on a chair'
          }}
        >
          <p>
            Большинству из нас повезло. Нам не пришлось ждать продолжения 20 долгих лет, за которых
            многих фанатов просто не стало. Продолжение вышло в 2017 году, и получилось не всё так
            гладко. В третьем сезона Линч получил полный контроль над проектом, да и сам он стал на
            20 уровней опытнее и смелее. Как итог, мы увидели полностью авторский многосерийный
            фильм, от которого часть зрителей были в восторге, а часть — в замешательстве, так как
            нашли в нем слишком мало духа оригинального сериала (я с вами, ребят). Если вам только
            предстоит просмотр, советую сделать перерыв после первых двух сезонов и покопаться в
            том, что осталось в этих десятках лет ожидания. Там есть и полнометражный фильм-приквел,
            и книги, и тонны фанатских размышлений.
          </p>

          <p>
            Вообще у каждого, кому сериал понравится, внутри загорится желание разобраться в
            оставшихся загадках Твин Пикса. Линч никогда не раскрывает свои тайны полностью, много
            перекладывается на принцип «у каждого зрителя своя трактовка», но всё-таки даже
            очевидные прямолинейные моменты легко упустить. Начинать углубление можно с таких
            разборов:{' '}
            <a
              href="http://twinpeaks.cinema.ru/TwinPeaks/faq_tv_m.htm"
              rel="noopener noreferrer"
              target="_blank"
            >
              twinpeaks.cinema.ru FAQ
            </a>
            .
          </p>

          <p>
            Конечно, этим сериалом был вдохновлен целый класс других произведений. Некоторые из них
            уже мелькали на{' '}
            <a href="https://vk.com/dark_fields" rel="noopener noreferrer" target="_blank">
              нашей странице ВК
            </a>
            . Со временем записи о них появятся и на сайте вместе со всеми остальными текстами.
          </p>
        </Section>

        <div className="article_section">
          <div className="container">
            <div className="share">
              <div className="share_message element">Поделиться с ближним</div>
              <div className="share_buttons">
                <SocialLinks post={{ slug }} />
              </div>
            </div>
          </div>
        </div>
      </article>
    </SpecialLayout>
  );
}

TwinPeaks.propTypes = propTypes;

export default TwinPeaks;

export const pageQuery = graphql`
  query TwinPeaksPageQuery {
    images: allFile(filter: { relativeDirectory: { eq: "twin-peaks" } }) {
      edges {
        node {
          name
          childImageSharp {
            sizes(maxWidth: 760, quality: 85, toFormat: JPG) {
              ...GatsbyImageSharpSizes_withWebp_noBase64
            }
          }
        }
      }
    }
    cover: file(relativeDirectory: { eq: "twin-peaks" }, name: { eq: "welcome2_cut" }) {
      childImageSharp {
        sizes(maxWidth: 1240, quality: 85, toFormat: JPG) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    share: file(relativeDirectory: { eq: "twin-peaks" }, name: { eq: "share" }) {
      childImageSharp {
        sizes(maxWidth: 1200, quality: 85, toFormat: JPG) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
  }
`;
